import getFiber from './locales/pages/getFiber.json'
import addressChecker from './locales/components/address-checker.json'
import header from './locales/components/header.json'
import homePage from './locales/pages/home.json'
import faqs from './locales/components/faqs.json'
import footer from './locales/components/footer.json'
import residential from './locales/pages/residential.json'
import installationEvents from './locales/components/installation-events.json'
import businessInternet from './locales/pages/business-internet.json'
import aboutPage from './locales/pages/about.json'
import contactPage from './locales/pages/contact.json'
import tosPage from './locales/pages/tos.json'
import privacyPage from './locales/pages/privacy.json'
import login from './locales/pages/login.json'
import forgotPasswordPage from './locales/pages/forgot-password.json'
import forgotPasswordHashPage from './locales/pages/forgot-password-hash.json'
import errorMessages from './locales/components/error-messages.json'
import testimonials from './locales/components/testimonials.json'
import ariaLabels from './locales/components/aria-labels.json'
import careersPage from './locales/pages/careers.json'
import serviceAreaPage from './locales/pages/service-areas.json'
import blog from './locales/pages/blog.json'
import residentialServicesAgreementPage from './locales/pages/residential-services-agreement.json'
import acceptableUsePolicyPage from './locales/pages/acceptable-use-policy.json'
import alreadyRegistered from './locales/pages/already-registered.json'
import pressPage from './locales/pages/press.json'
import latePayments from './locales/pages/late-payments.json'
import unactivatedUser from './locales/pages/unactivated-user.json'
import fcc from './locales/components/fcc.json'

const components = [
	getFiber,
	addressChecker,
	header,
	homePage,
	faqs,
	footer,
	residential,
	installationEvents,
	businessInternet,
	aboutPage,
	contactPage,
	tosPage,
	privacyPage,
	login,
	forgotPasswordPage,
	forgotPasswordHashPage,
	errorMessages,
	testimonials,
	ariaLabels,
	careersPage,
	serviceAreaPage,
	blog,
	residentialServicesAgreementPage,
	acceptableUsePolicyPage,
	alreadyRegistered,
	pressPage,
	latePayments,
	unactivatedUser,
	fcc
]
const en = Object.assign({},
	...components.map(({ en }) => en)
)
const es = Object.assign({},
	...components.map(({ es }) => es)
)

export default defineI18nConfig(() => ({
	legacy: false,
	globalInjection: true,
	missingWarn: false,
	messages: {
		en,
		es
	}
}))
